export const obtainAvailableValidations = {
  order: 1,
  name: "Obtain available validations",
  keywords: "explored area linked contact",
  subcategory: "Validate database changes",
  markdown: `# Obtain available validations

  Once a {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoint{% /inlineRouterLink %} progresses far enough in the {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}validation pipeline{% /inlineRouterLink %}, Tower Hunt invites users to vote on whether the proposed change is accurate and credible.

  ## Receiving validation invitations

  Tower Hunt uses two signals to distribute available validations:

  - The geographic location associated with a proposed change. This is compared to your {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %}.
  - When a proposed change involves a contact being linked to something (ex. an investment or property), Tower Hunt checks to see whether your account is {% inlineRouterLink articleId="link-your-account-to-a-contact" %}linked to that contact{% /inlineRouterLink %}.

  In each case, the goal is to seek validation from people who have a reasonable likelihood of being familiar with the underlying real estate information.

  ## Check your available validations

  You can easily check your currently available validations from the {% inlineRouterLink articleId="key-actions-hub" %}key actions hub{% /inlineRouterLink %} in the upper-right corner of the app:

  {% tablessContainer marginStyles="mb-4" %}
  1. Find your {% inlineAppIcon iconName="shieldCheck" %}**available validations**{% /inlineAppIcon %} count in the upper-right.
  2. If it is green, click it. If not, {% inlineRouterLink articleId="explored-areas" %}explore a new area of the map{% /inlineRouterLink %} where you have real estate knowledge until it turns green.
  ![Available validations count screenshot](https://assets.towerhunt.com/site/availableValidationsCount0.png)
  {% /tablessContainer %}

  ## Next steps

  Now that you understand how to obtain new validation opportunities, consider perusing our {% inlineRouterLink articleId="review-validations" %}guide to reviewing validations{% /inlineRouterLink %}. It explains all of the tools Tower Hunt offers to help assess the accuracy and credibility of proposed changes.`,
};
